body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
}

.loader-wrapper .loader {
  width: 50px;
  height: 50px;
}

.loader {
  text-indent: -9999em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #E7E7E3;
  background: -moz-linear-gradient(left, #E7E7E3 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #E7E7E3 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #E7E7E3 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #E7E7E3 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #E7E7E3 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load 1.4s infinite linear;
  animation: load 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #E7E7E3;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}